<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            <span>School's Payment List</span>
            <v-spacer></v-spacer>
            <add-button
              :on-current-batch="false"
              permission="school-create"
              @action="$router.push({ name: 'schools-form' })"
              >Create New Payment
            </add-button>
          </v-card-title>
          <v-card outlined>
            <v-card-title>
              <v-flex xs3>
                <v-select
                  @change="get()"
                  :items="[
                    { value: 'paid', text: 'Paid' },
                    { value: 'pending', text: 'Pending' },
                  ]"
                  class="pa-0"
                  label="Payment Status"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3>
                <v-select
                  @change="get()"
                  :items="[...months]"
                  class="pa-0"
                  label="Month"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex>
                <v-select
                  @change="get()"
                  :items="[]"
                  class="pa-0"
                  label="School"
                  outlined
                  dense
                />
              </v-flex>
            </v-card-title>
          </v-card>
          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="form.items.data"
            :search="search"
            :options.sync="pagination"
            footer-props.items-per-page-options="rowsPerPageItems"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left">
                  <select
                    class="rank_update"
                    name=""
                    v-model="item.rank"
                    @change="updateRank($event, item.id)"
                  >
                    <option disabled selected>-</option>
                    <option
                      :value="n"
                      :selected="n === item.rank"
                      v-for="n in form.items.meta.total"
                      :key="n"
                    >
                      {{ n }}
                      <span v-if="selectedRanks.includes(n) && n !== item.rank"
                        >&#10003;</span
                      >
                    </option>
                  </select>
                </td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-center">{{ item.permissions }}</td>
                <td class="text-center">{{ item.users }}</td>
                <td class="text-center">{{ item.users }}</td>
                <td class="text-right">
                  <view-button
                    :disabled="item.permissions < 1"
                    @action="(form.dialog = true), getPermissions(item.id)"
                    permission="roles-permissions-read"
                  >
                  </view-button>
                  <edit-button
                    color="warning"
                    icon="edit"
                    @agree="
                      $router.push({
                        name: 'edit-role',
                        params: { roleSlug: item.slug },
                      })
                    "
                    permission="roles-update"
                  >
                  </edit-button>
                  <delete-button
                    v-if="item.my && item.slug !== 'super'"
                    permission="roles-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
          <v-card-actions>
            <v-spacer />
            <!--<v-pagination v-model="pagination.page" :length="form.items.meta.last_page"></v-pagination>-->
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import Form from "@/library/Form";
import { getMonthNameFromString } from "../../../../library/helpers";

export default {
  data: () => ({
    form: new Form({ dialog: false }, "/api/payment-alert"),
    search: null,
    rowsPerPageItems: [5, 10, 25, 50, 75],
    pagination: {
      rowsPerPage: 50,
      descending: false,
    },
    headers: [
      {
        text: "#",
        align: "left",
        value: "sn",
        width: 10,
        sortable: false,
      },
      {
        text: "Head",
        align: "left",
        value: "head",
        sortable: false,
        width: 150,
      },
      {
        text: "Payment Date",
        align: "center",
        value: "payment_date",
        sortable: false,
        width: 100,
      },
      {
        text: "Receipt / Voucher",
        align: "center",
        value: "invoice_receipt",
        width: 100,
        sortable: false,
      },
      {
        text: "Status",
        align: "center",
        value: "status",
        width: 100,
        sortable: false,
      },
      { text: "Action", align: "right", sortable: false, width: 150 },
    ],
    chartData: [],
    chartOptions: {
      title: {
        text: "Role-wise Users",
        align: "center",
      },
      legend: {
        show: false,
        floating: true,
      },
      labels: [],
    },
    permissions: [],
  }),
  computed: {
    selectedRanks() {
      let output = [];
      this.form.items.data.filter(function(item) {
        if (item.rank !== 0) output.push(item.rank);
      });
      return output;
    },
    months() {
      const monthObj = getMonthNameFromString();
      return Object.keys(monthObj).map((value) => {
        return { text: monthObj[value], value: value };
      });
    },
  },
  watch: {
    pagination: function() {
      this.get();
    },
  },
  methods: {
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map((key) => {
            if (typeof json[key] === "object") {
              let vals = [...json[key]];
              if (vals.length)
                return (
                  encodeURIComponent(key) +
                  "=" +
                  encodeURIComponent(vals.join(","))
                );
              else return null;
            }
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
            );
          })
          .join("&")
      );
    },
    getPermissions(id) {
      this.permissions = [];
      this.$rest.get("/api/roles/" + id + "/permissions").then(({ data }) => {
        if (data.status === true) {
          this.permissions = data.permissions;
        }
      });
    },
    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        // this.form.items.data = [];
        let $this = this;
        let output = [];
        data.data.map(function(item) {
          if (item.slug === "super") {
            if ($this.$auth.getRole() === "super") {
              output.push(item);
            }
          } else {
            output.push(item);
          }
        });
        this.form.items.data = output;
        this.pagination.totalItems = data.meta.total;

        this.setChart(data.data);
      });
    },
    updateRank(e, roleId) {
      this.$rest
        .put(`api/roles/${roleId}/rank`, { rank: e.target.value })
        .then(({ data }) => {
          this.get();
          this.$events.fire("notification", {
            message: data.message,
            status: "success",
          });
        })
        .catch((err) => {
          if (err.response && err.response.data)
            this.$events.fire("notification", {
              message: err.response.data.message,
              status: "error",
            });
        });
    },

    setChart(data) {
      if (data == null) {
        data = this.form.items.data;
      }
      let labels = [];
      let chartData = [];
      data.map((item) => {
        if (item.name !== "Guardian") {
          labels.push(item.name);
          chartData.push(item.users);
        }
      });

      this.chartOptions.labels = labels;
      this.chartData = chartData;
    },
  },
};
</script>

<style lang="scss">
select.rank_update {
  outline: none;
}
</style>
