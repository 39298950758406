var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":"","pa-0":"","relative":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":""}},[_c('v-card',{staticClass:"primary--border",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"title"},[_c('span',[_vm._v("School's Payment List")]),_c('v-spacer'),_c('add-button',{attrs:{"on-current-batch":false,"permission":"school-create"},on:{"action":function($event){return _vm.$router.push({ name: 'schools-form' })}}},[_vm._v("Create New Payment ")])],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-flex',{attrs:{"xs3":""}},[_c('v-select',{staticClass:"pa-0",attrs:{"items":[
                  { value: 'paid', text: 'Paid' },
                  { value: 'pending', text: 'Pending' } ],"label":"Payment Status","outlined":"","dense":""},on:{"change":function($event){return _vm.get()}}})],1),_c('v-flex',{attrs:{"xs3":""}},[_c('v-select',{staticClass:"pa-0",attrs:{"items":[].concat( _vm.months ),"label":"Month","outlined":"","dense":""},on:{"change":function($event){return _vm.get()}}})],1),_c('v-flex',[_c('v-select',{staticClass:"pa-0",attrs:{"items":[],"label":"School","outlined":"","dense":""},on:{"change":function($event){return _vm.get()}}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"hide-default-footer":"","items":_vm.form.items.data,"search":_vm.search,"options":_vm.pagination,"footer-props.items-per-page-options":"rowsPerPageItems","server-items-length":_vm.form.items.meta.total,"loading":_vm.form.loading},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-left"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(item.rank),expression:"item.rank"}],staticClass:"rank_update",attrs:{"name":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(item, "rank", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.updateRank($event, item.id)}]}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v("-")]),_vm._l((_vm.form.items.meta.total),function(n){return _c('option',{key:n,domProps:{"value":n,"selected":n === item.rank}},[_vm._v(" "+_vm._s(n)+" "),(_vm.selectedRanks.includes(n) && n !== item.rank)?_c('span',[_vm._v("✓")]):_vm._e()])})],2)]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.permissions))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.users))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.users))]),_c('td',{staticClass:"text-right"},[_c('view-button',{attrs:{"disabled":item.permissions < 1,"permission":"roles-permissions-read"},on:{"action":function($event){(_vm.form.dialog = true), _vm.getPermissions(item.id)}}}),_c('edit-button',{attrs:{"color":"warning","icon":"edit","permission":"roles-update"},on:{"agree":function($event){return _vm.$router.push({
                      name: 'edit-role',
                      params: { roleSlug: item.slug },
                    })}}}),(item.my && item.slug !== 'super')?_c('delete-button',{attrs:{"permission":"roles-delete"},on:{"agree":function($event){return _vm.form.delete(item.id)}}}):_vm._e()],1)])]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"warning"},slot:"no-results"},[_vm._v(" Your search for \""+_vm._s(_vm.search)+"\" found no results. ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-spacer')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }